.portfolio__container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:1rem;


padding-right: 20px;

justify-items: center; /* This will center the items horizontally */
align-items: center;

}




.ParticleBackground{
    position: fixed !important;
display: block;
}

.portfolio__item{
   background: transparent;
    margin: 10px;
  
    text-align: center;
   
    transition: 0.5s;
    background-size: 200% auto;
    color: var(--color-white);           
    box-shadow: 0 0 20px #b18585;
   
    display: block;
 
    border: 2px solid black;
    border-radius: 10px  ;
    transition: var(--transition);
  
    padding: 10px 10px;
    }

    .portfolio__item:hover{
        border-color: var(--color-primary-variant);
        animation: bounce 0.8s ease-in ;
       
    }

    .portfolio__item-image{
        height:10rem;
        border-radius: 5px;
        overflow: hidden;
        border: 2px solid var(--color-white);

    }

    .portfolio__item h3{

        margin: 1.2rem 0 2 rem;
       font-size: small;
     font-weight: 550;

    }

    .portfolio__item-cta{
        display: flex;
        gap:1rem;
        margin-bottom: 1rem;
    }


    
/*---------------------------------------media query for medium devices*/
@media screen and (max-width:1300px) {
   
.portfolio__container{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap:1rem;


padding-right: 20px;

justify-items: center; /* This will center the items horizontally */
align-items: center;
}
    
}

/*---------------------------------------media query for small devices*/
@media screen and (max-width: 600px) {


    
.portfolio__container{
    grid-template-columns: 1fr;
    gap: 1rem;

  
}
    

.portfolio__item{
    width:90%;
   
}


.portfolio__item-cta{
    display: flex;
    gap:0px;
    margin-bottom: .5rem;
}
}
