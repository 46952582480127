.about__container{
    display: grid;
    grid-template-columns: 35% 50%;
    gap:15%;
}

.about__me{
    width:80%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg,
        transparent,
        var(--color-primary),
        transparent
    );

    display: grid;
    place-items: center;
}

.about__me-image {
    border-radius: 1rem;
    transition: transform 0.9s ease, box-shadow 0.2s ease;
}

.about__me-image:hover {
    transform: rotate(-10deg) scale(1.2);
    box-shadow: 0 0 10px rgba(31, 3, 94, 0.4);
  border-color: 20px solid black;
   
  background: #fff;
    
}


  
  @keyframes rotation {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
  }
  
  .fa-icon {
    position: absolute;
    
    
 
    transform: translate(-50%, -50%);
    font-size: 24px; /* Adjust the font size as needed */
   /* Adjust the animation duration and timing function as needed */
    animation-delay: 0.5s; /* Adjust the delay as needed */

    
  }
  
  .about__card:hover .fa-icon {
    
    animation: bounce 0.5s ease-in-out infinite; /* Adjust the animation duration and timing function as needed */
  }
  



.about__cards{
    display: grid;
    grid-template-columns : repeat(3,1fr);
    gap:1.5rem ;

}

.about__card{
    background-image: var( --background-gradient);
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
 
    transition: 0.5s;
    background-size: 200% auto;
              
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
    border: 1px solid transparent;
    
    padding: 5px;
 text-align: center;
 transition: var(--transition);
}






.about__card:hover{
    background-position: right center; /* change the direction of the change here */
  
    text-decoration: none;
    transform: scale(1.1);
}
.about__icon{
    color:var(--color-primary);
    font-size: 1.4rem;
    margin-top: 1rem;
   

}

.about__card h5{

    font-size: 0.95rem;
}

.about__card small{
    font-size: 1rem;
    color: var( --color-white);
}


.about__content p{
    margin: 2rem 0 2,6rem;
    color: var(--color-white);
}

.ParticleBackground{
    position: fixed !important;
display: block;
}



.about__card small a{
   
    color: var(--color-white);
}

/*---------------------------------------media query for medium devices*/
@media screen and (max-width:1024px) {
    .about__container{
        grid-template-columns: 1fr;
        gap:0;
    }

    .about__me{
        width:50%;
        margin: 2rem auto 4rem;
    }
     
    .about__content p{
        margin: 1rem 0 1.5rem;
    }

   

 }
 
 /*---------------------------------------media query for small devices*/
 @media screen and (max-width:600px) {
    .about__me{
        width:65%;
        margin: 0 auto 3rem;
    }

    .about__cards{
        grid-template-columns: 1fr 1fr;
        gap: .5rem;

    }
    .about__content{
        text-align: center;
        
    }
    .about__content p{
       
        margin: 1.5rem 0;
    }
     



    .about__card{
        background-image: var( --background-gradient);
        margin: 0px;
        padding: 5px 5px;
        text-align: center;
       
        transition: 0.5s;
        background-size: 200% auto;
                  
        box-shadow: 0 0 20px #eee;
        border-radius: 10px;
        display: block;
        border: 1px solid transparent;
        border-radius:5px;
       
     text-align: center;
     transition: var(--transition);
    }



    .about__icon{
        color:var(--color-white);
        font-size: 15px;
        margin-top: 10px;
       
    
    }
    
    .about__card h5{
    
        font-size: 10px;
    }
    
    .about__card small{
        font-size: 0.7rem;
        color: var(--color-white);
    }

    .about__card small a{
        font-size: 0.7rem;
        color: var(--color-white);
    }
    
    
    .about__content p{
        margin: 2rem 0 2,6rem;
        color: var(--color-white);
    }
    
    
    .about__content{
  margin-left: 10px;
        
    }
 }