.experience__container{
    display: grid;
    grid-template-columns:1fr 1fr ;
    gap:1rem;
    width: 100%;

    justify-content: center;
/* This will center the items horizontally */
  align-items: center;



}

.Web_Developer{
    background-image:var( --background-gradient);
    margin: 0px 0px;
    padding: 15px 43px;
    text-align: center;
 
    transition: 0.8s;
    background-size: 200% auto;
    color: var(--color-white);            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    

   min-width:fit-content;

   margin-left: 10%;
   margin-right: 10%;
    
  }

  .btn:hover {
    background-position: right center; /* change the direction of the change here */
 
    text-decoration: none;
  }
 




.experience__container >div:hover{
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
    transform: scale(1.1);
    
}

.experience__container >div h3{
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.experience__content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap:2rem;
   
}

.experience__details{
    display: flex;
    gap: 1rem;
}

  
  @keyframes rotation {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
  }
  
 
  .icon-container:hover .fa-icon {
    animation: bounce 0.5s ease-in-out infinite; /* Adjust the animation duration and timing function as needed */
  }
  
.experience__details-icon{
    margin-top: 6px;
    color: var(--color-primary);
   
    transform: translate(-50%, -50%);
    font-size: 24px; /* Adjust the font size as needed */
    animation: rotation 2s linear infinite; /* Adjust the animation duration and timing function as needed */
    animation-delay: 0.5s; /* Adjust the delay as needed */
}

.ParticleBackground{
    position: fixed !important;
display: block;
}


/*---------------------------------------media query for medium devices*/
@media screen and (max-width:1024px) {
  .experience__container{
    grid-template-columns: 1fr;
  }
 .experience__container >div{
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
 }
 .experience__content{
    padding: 1rem;
 }

 }
 
 /*---------------------------------------media query for small devices*/


 @media screen and (max-width:600px) {
    
  .experience__container{
     gap:1rem;

     justify-items:baseline; /* This will center the items horizontally */
     align-items: center;
  }
  .experience__container >div {
     width: 80%;
     padding: 10px 10px;
    
  }

  .experience__content{
     display: grid;
     grid-template-columns: 1fr 1fr;
     row-gap:.4rem;
  }
    
  .experience__container >div h3{
   text-align: center;
   margin-bottom: .1rem;
   color: var(--color-primary);
}



.experience__details{
 display: flex;
 gap: .1rem;
}



.experience__container >div{

margin: 5px;
padding: 0px 0px;
text-align: center;

transition: 0.8s;
background-size: 200% auto;
color: white;            
box-shadow: 0 0 20px #eee;
border-radius: 10px;
display: block;
width: 100%;
}

}