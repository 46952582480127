.headers{
  height: 100vh;
  padding-top: 2rem;
  width: 100vw;
}

.header_container{
  text-align: center;
  width: 100%;
}

.intro{
  transition: 0.7s ease-in;
}
.intro:hover{
  transform: scale(1.2);
}

/* Animations */
.animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.launch img {
  width: 100px;
  animation: launch-animation 3.5s forwards;
}

.awake img {
  width: 100px;
  animation: awake-animation 2.5s forwards;
}

@keyframes launch-animation {
  0% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  50% {
    transform: translate(200px, -200px);
    opacity: 0.7;
  }
  70% {
    transform: translate(300px, -300px);
    opacity: 0.4;
  }
  100% {
    transform: translate(400px, -400px);
    opacity: 0;
  }
}

@keyframes awake-animation {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Other existing styles */
.fad-icon {
  font-size: 12px;
  animation: bounce 2s ease infinite;
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

.fadd:hover {
  animation: bounce 0.9s ease-in;
}

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
  transition: 1s ease-in;
}

.cta:hover {
  transform: scale(1.2);
}

.Header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: xx-large;
  gap: 0.9rem;
  position: absolute;
  left: 1rem;
  bottom: 3rem;
  z-index: 999;
}

.Header__socials a {
  color: var(--color-white);
}

.Header__socials::after {
  content: '';
  width: 5px;
  height: 2rem;
  background: var(--color-primary);
}

.Me {
  width: 19rem;
  height: 20rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 5rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
  padding: 2rem 1.5rem 1.5rem;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  display: block;
}

.Me:hover {
  background-image: linear-gradient(to right, #000000 0%, #53346D 51%, #000000 100%);
  transform: scale(1.2);
  background-position: right;
  background: #fff;
}

.btn-grad:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

.scroll_down {
  position: absolute;
  color: rgb(245, 236, 236);
  right: -2.2rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}

@media screen and (max-width: 600px) {
  header {
    height: 100vh;
    width: 100%;
  }

  .Header__socials {
    display: flex;
    flex-direction: column;
  }

  .Me {
    width: 19rem;
    height: 20rem;
    position: absolute;
    left: calc(50% - 9.6rem);
  }
}

.toggle-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem;
  background-color: var(--color-primary);
  border: none;
  color: var(--color-white);
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  font-size: 1.2rem;
}

.toggle-btn:hover {
  background-color: var(--color-primary-variant);
}

.toggle-btn svg {
  transition: transform 0.3s ease;
}

.toggle-btn .fa-moon {
  transform: rotate(-20deg);
}

.toggle-btn .fa-sun {
  transform: rotate(20deg);
}
