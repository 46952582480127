
@import url('https://fonts.googleapis.com/css2?family=Arizonia&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

:root {
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --container-width-lg: 75%;
    --container-width-md: 100%;
    --container-width-sm: 100%;

    --transition: all 400ms ease;
    --background-gradient: linear-gradient(to right, #000000 0%, #53346D 51%, #000000 100%);

    
}

body.light-mode {
    --color-bg: #ffffff;
    --color-bg-variant: #f0f0f0;
    --color-primary: #badbef;
    --color-primary-variant: rgba(148, 185, 209, 0.4);
    --color-white: #010101;
    --color-light: rgba(0, 0, 0, 0.6);
    background-color: #ffffff;
    color: #000000;
    
    --background-gradient: linear-gradient(to right, #ff758c 0%, #ff1744 50%, #ff80d1 100%);
    --background-gradient: linear-gradient(to right, #f18294 0%, #f9739b 50%, #ff8b8b 100%);
   
    
}



html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: "Poppins", sans-serif;
    background-image: url("./assets/background.png");
    background-color: var(--color-bg);
    color: var(--color-white);

    width: 100%;
}

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
    align-items: center;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-white);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-white);
}

a {
    color: var(--color-white);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn, .btn-primary {
    background-image:var( --background-gradient);
    
    margin: 5px;
    padding: 10px 30px;
    text-align: center;
  
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
}

.btn:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 15%;
    }
}

@media screen and (max-width: 600px) {
    .container {
        width: 100%;
    }

    section > h2 {
        margin-bottom: 15%;
    }
}

