.container.contact__container{
   
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:12%;
    
    
}


.contact__options{
    
   display: flex;
   flex-direction: row;
   gap: 1.2rem;
   
}

.contact__option{
  background-image: var( --background-gradient);
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);

}

.contact__option:hover{
  animation: bounce 0.8s ease-in ;
    border-color: var(--color-primary-variant);
}



  
  @keyframes rotation {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
  }
  

  
  .icon-container:hover .fa-icon {
    animation: bounce 0.5s ease-in-out infinite; /* Adjust the animation duration and timing function as needed */
  }
  

.contact__option-icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    
  
    font-size: 24px; /* Adjust the font size as needed */
    animation: bounce 0.5s ease-in-out infinite;  /* Adjust the animation duration and timing function as needed */
    animation-delay: 0.5s;

}

.contact__option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}




    
/*---------------------------------------media query for medium devices*/
@media screen and (max-width:1024px) {
    .container.contact__container{
        grid-template-columns: 1fr;
        gap:2rem;
    }
   
        
    }
    
    /*---------------------------------------media query for small devices*/
    @media screen and (max-width: 600px) {
        
   .container.contact__container{
    width: var(--container-width-sm);
   }
        
    }
    